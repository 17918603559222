import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from 'app/_shared/services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class IsAuthedGuard implements CanActivate {
	constructor(
		private router: Router,
		private authSrv: AuthService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const redirectToLogin: boolean = route.data?.isAuthedGuard?.redirectToLogin;
		const redirectUrl: string = route.data?.isAuthedGuard?.redirectUrl;

		return this.authSrv.user$.pipe(
			filter((u) => u !== undefined),
			tap((u) => console.log('IsAuthedGuard:', !!u)),
			tap((user) => {
				if (!user) {
					if (redirectToLogin) {
						const params: Params = {};
						route.queryParamMap.keys.forEach((k) => (params[k] = route.queryParamMap.get(k)));
						params.redirectOnSuccessUrl = state.url;
						this.router.navigate(['/auth/login-email'], { queryParams: params });
					} else if (redirectUrl) {
						this.router.navigateByUrl(redirectUrl);
					}
				}
			}),
			map((u) => !!u)
		);
	}
}
